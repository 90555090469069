import { FC } from 'react';
import { AspectRatio, Box, Container, Flex } from '@chakra-ui/react';
import Teaser from './Teaser';
import Image from './Image';
import { HeroProps, MhyChakraProps } from '../types';

const TeaserHeroFull: FC<HeroProps & Omit<MhyChakraProps, 'variant'>> = ({
  heading,
  text,
  image,
  campaign,
  buttons,
  children,
  ...restChakraProps
}) => (
  <Box layerStyle="fullWidth" {...restChakraProps} data-test-id="teaser-hero">
    <AspectRatio
      bg="greenBirch"
      ratio={[375 / 447, 375 / 447, 1440 / 600]}
      maxH={[null, null, null, '60vh']}
      minH={[null, null, null, '407px']}
      sx={{ '&::before': { pointerEvents: 'none' } }}
    >
      <Image
        src={image?.src}
        alt={image?.alt || heading.title}
        layout="fill"
        priority
        noRounding
      />
    </AspectRatio>
    <Flex
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      width="100%"
      alignItems="center"
    >
      <Container maxW="container.xl" variant="noSpacing">
        <Flex py={10} px={[5, 10]} pl={[null, null, null, '8.33333333%']}>
          <Box flex={[null, null, null, '0 0 75%', '0 0 50%']}>
            <Teaser
              heading={{ title: heading.title, importance: 'h1' }}
              variant="white"
              text={text}
              textStyle="lead"
              buttons={
                buttons && buttons?.map((btn) => ({ ...btn, size: 'xl' }))
              }
            >
              {children}
            </Teaser>
          </Box>
        </Flex>
      </Container>
    </Flex>
  </Box>
);

export default TeaserHeroFull;
